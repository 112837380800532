.grid {
	display:flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 95vw;

	justify-content: center;
	align-items: stretch;
}
.about .grid li {
	height:700px;
	padding:20px;
	background-clip: content-box;
	background-size: cover;
	background-position: center;
}

.small {
	flex-basis:40%;
}

.large {
	flex-basis:60%;
	border:10px blue;
}

@media (max-width:1100px) {
	.about .grid .small,
	.about .grid .large {
		flex-basis: 50%;
	}
	.about .grid li {
		height:400px;
	}
}

@media (max-width:800px) {
	.about .grid .small,
	.about .grid .large {
		flex-basis: 100%;
	}
}

section.buffer {
	margin: 0 0 20px 0;
  height: 80px;
}

@media (max-width: 1000px) {
	section {
		flex-basis: 100%;
		margin: 0 0 20px 0;
	}
}


.showcase {
	display: flex;
	position: relative;
	justify-content: space-around;
	text-align: center;
	min-height: 20vh;
	width: 100vw;
	flex-basis: 200px;

}
@media (max-width: 800px) {
	.showcase {
		flex-direction: column;
		padding-top: 300px;
		padding-bottom: 300px;
	}
}

.showitem1 {
	background:#3f51b5;
	font-size: 28px;
	color:#fff;
	padding: 40px;
	flex-grow: 1;
	text-shadow: 2px 2px black;

}
.showitem2 {
	background:#80a3db;
	font-size: 28px;
	color:#fff;
	padding: 40px;
	flex-grow: 1;
	text-shadow: 2px 2px black;

}
.showitem3 {
	background:#3f51b5;
	font-size: 28px;
	color:#fff;
	padding: 40px;
	flex-grow: 1;
	text-shadow: 2px 2px black;
}

.showitem4 {
	background:#80a3db;
	font-size: 28px;
	color:#fff;
	padding: 40px;
	flex-grow: 1;
	text-shadow: 2px 2px black;

}
/*General Styles*/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font: normal 16px sans-serif;
	color: #555;
}

ul,
nav {
	list-style: none;
}

a {
	text-decoration: none;
	opacity: .8;
	color: #fff;
}

a.btn {
	border-radius: 4px;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	background-color: #3f51b5;
	opacity: 1;

}

section {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 80px;
}

section:not(.hero):nth-child(even) {
	background-color: #f5f5f5;
}

.grid {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

hr {
	width: 250px;
	height: 3px;
	background-color: #3f51b5;
	border: 0;
	margin-bottom: 50px;
}

section h3.title {
	text-transform: capitalize;
	font: bold 32px "Open Sans", sans-serif;
	margin-bottom: 30px;
	text-align: center;
}

section p {
	max-width: 1000px;
	line-height: 2;
	padding: 0 20px;
	margin-bottom: 30px;
	text-align: center;
}

section p.left {
	max-width: 1000px;
	line-height: 2;
	padding: 0 20px;
	margin-bottom: 30px;
	text-align: left;
}

@media (max-width: 800px) {
	section {
		padding: 50px 20px;
	}
}

h1 {
	text-align:center
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.mtg h3 {
	color:	#3f51b5;
}

.mtg:hover {
    position: relative;
}

.mtgDecks {
	display: flex;
	justify-content: space-around;
}

.bonecrusherGiant:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Bonecrusher%2BGiant%2B%255BELD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.brazenBorrower:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Brazen%2BBorrower%2B%255BELD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.goldspanDragon:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Goldspan%2BDragon%2B%255BKHM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.alrundsEpiphany:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Alrund%2527s%2BEpiphany%2B%255BKHM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.spikefieldHazard:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Spikefield%2BHazard%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.essenceScatter:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Essence%2BScatter%2B%255BAKH%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.jwariDisruption:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Jwari%2BDisruption%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.negate:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Negate%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.cinderclasm:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Cinderclasm%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.expressiveIteration:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Expressive%2BIteration%2B%255BSTX%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.mysticalDispute:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Mystical%2BDispute%2B%255BELD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.sawItComing:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Saw%2BIt%2BComing%2B%255BKHM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.prismariCommand:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Prismari%2BCommand%2B%255BSTX%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.magmaOpus:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Magma%2BOpus%2B%255BSTX%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.snowCoveredIsland:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Snow-Covered%2BIsland%2B%253C279%253E%2B%255BKHM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.snowCoveredMountain:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Snow-Covered%2BMountain%2B%253C283%253E%2B%255BKHM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.riverglidePathway:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Riverglide%2BPathway%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.volatileFjord:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Volatile%2BFjord%2B%255BKHM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.fabledPassage:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Fabled%2BPassage%2B%253Cplaneswalker%2Bstamp%253E%2B%255BPELD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.lurrusoftheDreamDen:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Lurrus%2Bof%2Bthe%2BDream-Den%2B%255BIKO%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.deathsShadow:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Death%2527s%2BShadow%2B%255B2XM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.dragonsRageChanneler:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Dragon%2527s%2BRage%2BChanneler%2B%255BMH2%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.ragavanNimblePilferer:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Ragavan%252C%2BNimble%2BPilferer%2B%255BMH2%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.scourgeoftheSkyclaves:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Scourge%2Bof%2Bthe%2BSkyclaves%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.snapcasterMage:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Snapcaster%2BMage%2B%255BISD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.dismember:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Dismember%2B%255BMM2%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.counterspell:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Counterspell%2B%255BMH2%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.fatalPush:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Fatal%2BPush%2B%255B2XM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.inquisitionofKozilek:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Inquisition%2Bof%2BKozilek%2B%255BSTA%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.lightningBolt:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Lightning%2BBolt%2B%255BM10%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.stubbornDenial:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Stubborn%2BDenial%2B%255BKTK%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.temurBattleRage:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Temur%2BBattle%2BRage%2B%255B2XM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.thoughtseize:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Thoughtseize%2B%255B2XM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.MishrasBauble:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Mishra%2527s%2BBauble%2B%255B2XM%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.bloodCrypt:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Blood%2BCrypt%2B%255BSLD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.bloodstainedMire:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Bloodstained%2BMire%2B%255BKTK%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.island:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Island%2B%253C251%253E%2B%255BTHB%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.pollutedDelta:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Polluted%2BDelta%2B%255BKTK%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.scaldingTarn:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Scalding%2BTarn%2B%253Cretro%253E%2B%255BMH2%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.steamVents:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Steam%2BVents%2B%255BGRN%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.swamp:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Swamp%2B%253C252%253E%2B%255BTHB%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.wateryGrave:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Watery%2BGrave%2B%255BGRN%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.advancedStitchwing:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/h/Advanced-Stitchwing-EMN-265.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.elderDeepFiend:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Elder%2BDeep-Fiend%2B%253Cprecon%253E%2B%255BCMR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.prizedAmalgam:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Prized%2BAmalgam%2B%255BSOI%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.stitchwingSkaab:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/h/Stitchwing-Skaab-SOI-265.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.catharticReunion:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Cathartic%2BReunion%2B%255BIKO%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.feveredVisions:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Fevered%2BVisions%2B%255BSOI%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.kozileksReturn:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Kozilek%2527s%2BReturn%2B%255BOGW%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.fieryTemper:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/h/Fiery-Temper-C19-265.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.tormentingVoice:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Tormenting%2BVoice%2B%255BZNR%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.mountain:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Mountain%2B%253C253%253E%2B%255BTHB%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.sanctumofUgin:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/h/Sanctum-of-Ugin-BFZ-265.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.spirebluffCanal:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Spirebluff%2BCanal%2B%255BKLD%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.wanderingFumarole:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Wandering%2BFumarole%2B%255BOGW%255D.jpg);
    display: block;
    position: absolute;
    left: 423px;
    top: 00px;
}

.hero h2 {
	font: bold 20px "Open Sans", sans-serif;
	margin-bottom: 20px;
	color: #80a3db;
}

.hero h4 {
	font: bold 12px "Open Sans", sans-serif;
	margin-bottom: 8px;
	color: #80a3db;
}

.grid {
	display:flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 95vw;

	justify-content: center;
	align-items: stretch;
}
.cats .grid li {
	height:800px;
	padding:20px;
	background-clip: content-box;
	background-size: cover;
	background-position: center;
}

.small {
	flex-basis:35%;
}

.large {
	flex-basis:65%;
	border:10px blue;
}

@media (max-width:1700px) {
	.cats .grid .small,
	.cats .grid .large {
		flex-basis: 50%;
	}
	.about .grid li {
		height:400px;
	}
}

@media (max-width:1000px) {
	.cats .grid .small,
	.cats .grid .large {
		flex-basis: 100%;
	}
}

.cats .mtg:hover {
    position: relative;
}
.cats .mtg:hover:after {
    content: url(https://cdn1.mtggoldfish.com/images/gf/Alrund%2527s%2BEpiphany%2B%255BKHM%255D.jpg); /* no need for qoutes */
    display: block;
    position: absolute;
    left: 123px; /* change this value to one that suits you */
    top: -300px; /* change this value to one that suits you */
}

.workExp {
	display: flex;
	flex-direction: column;
	border: 5px solid black;
}

.Resume .job {
	font-size: 22px;
	font-weight: 700px;
	line-height: 1.5;
	margin: 40px 0 25px;
	border-width: 10px;
}

@media (max-width: 800px) {
	.Resume .job {
		font-size: 18px;
		margin: 15px 0;
	}
}

.Resume .btn {
	padding: 20px;
}

.exp {
	cursor: pointer;
}

.exp:hover {
	color: black;
	font-size: larger;
}